'use client';

import type {
    NextDynamicOption,
    NextPreferredRegionOption,
    NextRuntimeOption,
} from '@/types/general';
import { captureException } from '@sentry/nextjs';
import Error from 'next/error';
import { useEffect } from 'react';

export const dynamic: NextDynamicOption = 'force-static';

export const runtime: NextRuntimeOption = 'nodejs';

export const preferredRegion: NextPreferredRegionOption = 'global';

export default function GlobalError({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        captureException(error);
    }, [error]);

    return (
        <html>
            <body>
                <h2>Something went wrong!</h2>
                <button onClick={() => reset()}>Try again</button>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <Error statusCode={undefined as any} />
            </body>
        </html>
    );
}
